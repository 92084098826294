import React from 'react';

const PostCard404: React.FC = () => {

    return (
        <span className="postcard404" >
            Niestety nie ma żadnych postów do wyświetlenia ;'(
        </span>
    );
};

export default PostCard404;