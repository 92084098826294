

import React from 'react';

const Page404: React.FC = () => {
    return (
        <>

            <h1
                style={{
                    margin: "100px",
                }}
            >
                404
            </h1>

        </>
    );
}

export default Page404;
