// import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

export const firebaseConfig = {
    apiKey: "AIzaSyCFOLz8OeLn8y1KzDj8TDOAVtlHaZPNI8U",
    authDomain: "bloggy-6cd0c.firebaseapp.com",
    databaseURL: "https://bloggy-6cd0c.firebaseio.com",
    projectId: "bloggy-6cd0c",
    storageBucket: "bloggy-6cd0c.appspot.com",
    messagingSenderId: "327002484739",
    appId: "1:327002484739:web:bf59d6de79729d0e319e36",
    measurementId: "G-SX35Q616LT"
};


